import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1014.000000 1080.000000" >
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)" >


<path d="M5626 9084 c-42 -42 9 -83 190 -153 155 -60 315 -173 470 -332 117
-121 168 -191 224 -303 l45 -91 49 -3 49 -3 -6 42 c-22 161 -297 490 -556 663
-239 160 -417 229 -465 180z"/>
<path d="M5538 8770 c-51 -31 -4 -78 166 -164 155 -79 255 -154 345 -256 93
-108 95 -110 131 -179 33 -62 80 -111 105 -111 7 0 21 7 30 17 57 56 -87 273
-300 453 -202 170 -415 278 -477 240z"/>
<path d="M5446 8451 c-33 -37 -18 -54 104 -119 153 -83 240 -158 322 -281 57
-84 73 -100 104 -101 84 -1 46 121 -82 258 -177 191 -391 307 -448 243z"/>
<path d="M3200 8195 c-216 -31 -339 -63 -529 -137 -322 -126 -647 -371 -861
-648 -19 -25 -37 -47 -40 -50 -9 -9 -81 -121 -123 -193 -137 -234 -237 -542
-267 -828 -55 -524 114 -1088 453 -1507 l67 -83 0 -1069 0 -1070 1520 0 1520
0 1 580 c1 319 2 587 2 595 0 48 62 45 -913 45 l-910 0 0 126 0 126 58 -6
c494 -53 948 46 1352 295 189 117 387 291 526 463 169 211 332 531 391 770
l16 66 958 0 c528 0 959 -3 959 -7 0 -5 1 -419 3 -921 l2 -912 -460 0 -460 0
3 755 c1 415 -1 755 -5 755 -5 0 -279 -272 -610 -604 l-603 -603 0 -757 0
-756 1675 0 1675 0 0 2130 0 2130 -1611 0 -1610 0 -16 38 c-30 73 -110 228
-146 285 -20 31 -37 60 -37 62 0 6 -90 128 -140 190 -47 59 -157 172 -234 240
-88 78 -283 215 -366 257 -36 18 -67 35 -70 38 -12 13 -234 101 -328 130 -262
80 -597 110 -842 75z"/>
<path d="M5335 8088 c-76 -26 -130 -117 -141 -234 l-6 -69 56 -53 c66 -62 108
-82 174 -82 167 0 269 178 191 332 -45 88 -177 140 -274 106z"/>
<path d="M2679 2232 c-91 -37 -149 -99 -176 -187 -41 -133 35 -273 176 -324
78 -28 133 -25 214 12 55 25 57 25 67 6 6 -11 21 -19 35 -19 l25 0 0 140 0
140 -120 0 -120 0 0 -35 0 -35 60 0 c58 0 60 -1 60 -26 0 -15 -12 -38 -29 -55
-26 -26 -37 -29 -89 -29 -74 0 -117 26 -150 90 -18 34 -22 54 -17 85 21 140
169 180 307 82 15 -10 23 -6 55 25 l37 37 -24 27 c-69 73 -216 104 -311 66z"/>
<path d="M3280 2227 c-230 -90 -239 -389 -15 -494 151 -70 333 -6 395 139 33
76 26 174 -17 243 -73 116 -230 164 -363 112z m204 -115 c48 -28 69 -66 73
-129 3 -45 0 -65 -16 -90 -28 -44 -88 -73 -151 -73 -60 0 -97 17 -134 60 -22
27 -26 41 -26 100 0 64 2 70 35 104 19 19 47 38 62 42 15 3 33 7 38 10 19 7
90 -7 119 -24z"/>
<path d="M6170 1975 l0 -265 145 0 c79 0 168 5 197 11 134 28 209 119 211 256
2 123 -81 226 -203 252 -28 6 -118 11 -200 11 l-150 0 0 -265z m370 130 c56
-50 71 -152 32 -220 -29 -49 -73 -65 -184 -65 l-98 0 0 156 0 156 114 -4 c97
-2 117 -6 136 -23z"/>
<path d="M6991 2138 c-27 -57 -83 -175 -125 -262 -42 -87 -76 -161 -76 -162 0
-2 29 -4 65 -4 l65 0 20 40 20 40 144 0 145 0 17 -40 c18 -40 18 -40 75 -40
32 0 60 4 63 9 3 5 -28 80 -70 167 -41 88 -96 203 -121 257 l-46 97 -64 0 -64
0 -48 -102z m195 -235 c-2 -2 -42 -3 -89 -1 l-85 3 45 95 45 95 44 -94 c24
-52 43 -96 40 -98z"/>
<path d="M7390 2185 l0 -55 110 0 110 0 0 -210 0 -210 60 0 60 0 0 210 0 210
115 0 115 0 0 55 0 55 -285 0 -285 0 0 -55z"/>
<path d="M8126 2138 c-162 -343 -194 -414 -187 -421 5 -5 32 -7 62 -5 51 3 55
5 74 40 l20 38 145 0 145 0 17 -40 17 -40 60 0 c33 0 62 3 64 8 2 4 -17 52
-44 107 -148 310 -177 372 -183 393 -7 20 -14 22 -75 22 l-67 0 -48 -102z
m162 -145 l41 -88 -86 -3 c-47 -2 -87 -1 -90 1 -5 6 78 177 87 177 4 0 25 -39
48 -87z"/>
<path d="M1950 1975 l0 -255 231 0 230 0 -3 48 -3 47 -168 3 -168 2 3 53 3 52
143 3 142 3 0 44 0 45 -145 0 -145 0 0 55 0 55 165 0 165 0 0 50 0 50 -225 0
-225 0 0 -255z"/>
<path d="M3780 1975 l0 -255 187 0 c180 0 188 1 229 25 81 47 93 147 29 224
-22 25 -24 33 -14 58 28 72 3 146 -61 182 -34 19 -54 21 -204 21 l-166 0 0
-255z m313 148 c25 -23 21 -58 -9 -82 -22 -17 -41 -21 -100 -21 -41 0 -74 3
-75 8 -1 14 -1 97 0 107 2 17 163 6 184 -12z m44 -205 c7 -6 13 -25 13 -43 0
-18 -6 -37 -13 -43 -14 -12 -227 -21 -228 -9 0 4 0 30 0 57 0 28 0 51 0 53 2
7 218 -6 228 -15z"/>
<path d="M4370 1975 l0 -255 230 0 230 0 0 50 0 50 -172 0 -173 0 3 55 4 55
138 0 c152 0 151 0 142 62 l-4 27 -140 3 -140 3 -2 53 -1 52 168 0 167 0 0 50
0 50 -225 0 -225 0 0 -255z"/>
<path d="M4920 1975 l0 -255 225 0 225 0 0 50 0 50 -165 0 -165 0 0 205 0 205
-60 0 -60 0 0 -255z"/>
<path d="M5460 1975 l0 -255 225 0 225 0 0 50 0 50 -165 0 -165 0 0 205 0 205
-60 0 -60 0 0 -255z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
